import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import { HiExclamationCircle } from "react-icons/hi";

const Textarea = (props) => {
  const {
    children,
    label,
    size,
    charLimit = 1000,
    variant,
    block,
    className,
    disabled,
    type,
    placeholder,
    value,
    onChange,
    error,
    errorMessage,
    ...rest
  } = props;

  const defaultClass = `button`;
  const inputRef = useRef(null);
  const [textCount, setTextCount] = useState(0);

  useEffect(() => {
    if (error && inputRef.current) {
      inputRef.current.focus();
    }
  }, [error]);

  const getInputSize = () => {
    let sizeClass = "";
    switch (size) {
      case "large":
        sizeClass = classNames(
          `w-full min-h-[120px] text-base rounded-lg py-4 px-5`
        );
        break;
      case "medium":
        sizeClass = classNames(
          `w-full min-h-[80px] text-[14px] rounded-lg py-3 px-4`
        );
        break;
      default:
        sizeClass = classNames(
          `w-full min-h-[48px] text-[14px] rounded-lg py-3 px-4`
        );
        break;
    }
    return sizeClass;
  };

  const classes = classNames(
    defaultClass,
    getInputSize(),
    className,
    disabled ? "cursor-not-allowed text-gray-400 bg-gray-100 border border-gray-200" : ""
  );

  const onCharChange = (e) => {
    let text = e.target.value;
    var chars = text.replace(/[\n\s]/g, "");
    if (chars?.length <= charLimit) {
      onChange(e);
      setTextCount(chars?.length);
    } else {
      if (text === " " || text === null) {
        e.preventDefault();
        return;
      }
    }
  };

  return (
    <>
      <label
        className={`text-[16px] ${error ? "text-accentRed" : "text-dark"} `}
      >
        {label}
      </label>
      <TextareaAutosize
        ref={inputRef}
        name="description"
        label="Description"
        className={`${classes}  appereance-none active:border-accessiblePurple focus:border-accessiblePurple ${error ? "border border-accentRed" : "border border-dark"
          }`}
        placeholder={placeholder}
        value={value}
        maxRows={20}
        onChange={onCharChange}
        disabled={disabled ? true : false}
        {...rest}
      />


      <div className={`flex  items-center ${error?"justify-between":"justify-end"}`}>
        {error && (
          <span className="flex  text-red-500 font-semibold  text-[16px]">
            <HiExclamationCircle className="mr-2 text-red-500" size={20} />
            {errorMessage}
          </span>
        )}
        <div className="flex justify-between items-center" id="the-count">
          <span id="current">{textCount}</span>
          <span id="maximum">/ {charLimit}</span>
        </div>
      </div>
    </>
  );
};

export default Textarea;
