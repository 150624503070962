import { combineReducers } from "@reduxjs/toolkit";
import daycare from "./daycareSlice";
import parent from "./parentSlice";

const reducer = combineReducers({
  daycare,
  parent,
});

export default reducer;
