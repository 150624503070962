import { createSlice } from '@reduxjs/toolkit'

export const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        filterData: "",
        daycareApplicationsFilterData: "",
        daycareEnrolledChildrenFilterData: "",
        waitlistDateSortData: "",
    },
    reducers: {
        setFilterData: (state, action) => {
            //   console.log("🚀 ~ user action.payload:", action.payload)
            state.filterData = action.payload
        },
        setDaycareApplicationsFilterData: (state, action) => {
            //   console.log("🚀 ~ user action.payload:", action.payload)
            state.daycareApplicationsFilterData = action.payload
        },
        setDaycareEnrolledChildrenFilterData: (state, action) => {
            //   console.log("🚀 ~ user action.payload:", action.payload)
            state.daycareEnrolledChildrenFilterData = action.payload
        },

    },
})

export const { setFilterData, setDaycareApplicationsFilterData,setDaycareEnrolledChildrenFilterData,setWaitlistDateSortData } = filterSlice.actions

export default filterSlice.reducer