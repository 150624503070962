import React from "react";
import { PAGES_PREFIX_PATH } from "../../constants/route.constant";
import {USER, DAYCARE, PARENT } from "../../constants/roles.constant";

const pagesRoute = [
  {
    key: "pages.accessDenied",
    path: `${PAGES_PREFIX_PATH}/access-denied`,
    component: React.lazy(() => import("../../views/pages/accessDenied")),
    authority: [DAYCARE, PARENT],
  },
  
];

export default pagesRoute;
