import React,{useState} from 'react';
import MyContext from './myContext';

const MyProvider = (props) => {
    const[isBoxOpen,setIsBoxOpen]=useState(false)

  return (
    <MyContext.Provider value={{isBoxOpen,setIsBoxOpen}}>
      {props.children}
    </MyContext.Provider>
  );
}

export default MyProvider;
