import React from "react";
import { USER, DAYCARE, PARENT } from "../../constants/roles.constant";
import { Daycare } from "assets/images";

const commonRoute = [
  {
    key: "user-selectAccount",
    path: `/select-account`,
    component: React.lazy(() =>
      import("../../views/profileForm/selectAccount")
    ),
    authority:[USER,PARENT,DAYCARE],
    header: false,
    footer: false,
  },
  {
    key: "profile-notification",
    path: `/profile-notification`,
    component: React.lazy(() =>
      import("../../views/profileForm/profileNotification")
    ),
    authority: [USER,PARENT,DAYCARE],
    header: false,
    footer: false,
  },
  {
    key: "daycare-profile",
    path: `/daycare-profile`,
    component: React.lazy(() =>
      import("../../views/profileForm/daycareProfile")
    ),
    authority: [USER,DAYCARE,PARENT],
    header: false,
    footer: false,
  },
  {
    key: "parent-profile",
    path: `/parent-profile`,
    component: React.lazy(() =>
      import("../../views/profileForm/parentProfile")
    ),
    authority: [USER,DAYCARE,PARENT],
    header: false,
    footer: false,
  },
 
];

export default commonRoute;
