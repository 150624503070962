const appConfig = {
  apiPrefix: "/api",
  // authenticatedEntryPath: {
  //   parent: "/",
  //   daycare: "/",
  //   user: "/",
  // },
  unAuthenticatedEntryPath: "/",
  tourPath: "/select-account",
  authenticatedEntryPath:"/",
  enableMock: true,
  pagePerData: 5,
};
export default appConfig;
