import { createSlice } from "@reduxjs/toolkit";

const paginationSlice = createSlice({
  name: "pagination",
  initialState: {
    homePaginationData: "",
    browseDaycaresPaginationData:"",
    myFavouritesPaginationData:"",
    ourChildernPaginationData:"",
    applicationsPaginationData:"",
    waitlistPaginationData:"",
    paymentHistoryPaginationData:"",
    staffPaginationData:"",
    faqPaginationData:"",
  },
  reducers: {
    setHomePaginationData: (state, action) => {
      state.homePaginationData = action.payload;
    },
    setBrowseDaycaresPaginationData: (state, action) => {
      // console.log("🚀 ~ initialState:", state.daycareListPagination)
      // console.log("🚀 ~ State payload:", action.payload)
      state.browseDaycaresPaginationData = action.payload;
    },
    setMyFavouritesPaginationData: (state, action) => {
      state.myFavouritesPaginationData = action.payload;
    },
    setOurChildernPaginationData: (state, action) => {
      state.ourChildernPaginationData = action.payload;
    },
    setApplicationsPaginationData: (state, action) => {
      state.applicationsPaginationData = action.payload;
    },
    setWaitlistPaginationData: (state, action) => {
      state.waitlistPaginationData = action.payload;
    },
    setPaymentHistoryPaginationData: (state, action) => {
      state.paymentHistoryPaginationData = action.payload;
    },
    setStaffPaginationData: (state, action) => {
      state.staffPaginationData = action.payload;
    },
    setFaqPaginationData: (state, action) => {
      state.faqPaginationData = action.payload;
    },
  },
});

export const {
  setHomePaginationData,
  setBrowseDaycaresPaginationData,
  setMyFavouritesPaginationData,
  setOurChildernPaginationData,
  setApplicationsPaginationData,
  setWaitlistPaginationData,
  setPaymentHistoryPaginationData,
  setStaffPaginationData,
  setFaqPaginationData
} = paginationSlice.actions;

export default paginationSlice.reducer;