import { combineReducers } from "redux";
import auth from "./auth";
import profile from "./profile";
import pagination from "./pagination";
import filter from "./filter";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    profile,
    pagination,
    filter,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
