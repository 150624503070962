import Table from './table'
import THead from './tHead'
import TBody from './tBody'
import TFoot from './tFoot'
import Tr from './tr'
import Th from './th'
import Td from './td'
import Sorter from './sorter'
 
Table.THead = THead
Table.TBody = TBody
Table.TFoot = TFoot
Table.Th = Th
Table.Tr = Tr
Table.Td = Td
Table.Sorter = Sorter

export default Table