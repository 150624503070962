import React from "react";
import ReactDOM from "react-dom/client";
// import "./app.min.css";
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store'
import App from "./App";
import "./index.css";
import history from "./history";
import reportWebVitals from "./reportWebVitals";
import MyProvider from "context/myProvider";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MyProvider>
          <BrowserRouter history={history}>
            <App />
          </BrowserRouter>
        </MyProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
