import React from "react";

const Steps = ({ totalSteps, currentStep, setSteps }) => {
  return (
    <div className="flex w-full justify-center">
      <div className="flex items-center justify-between w-[200px] xs:w-[550px] sm:w-[400px]  ">
        {Array.from({ length: totalSteps }, (_, index) => (
          <div
            key={index}
            className={`w-[24px] h-[24px] rounded-full  ${
              index < currentStep
                ? "bg-[#00C1C1]" // Color for completed steps
                : "bg-[#ABEBEB]" // Color for incomplete steps
            }`}
            onClick={() => {
              console.log("steps index : ", index);
              setSteps(index); // Pass the index to the setSteps function
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Steps;
