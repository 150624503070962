import { createSlice } from "@reduxjs/toolkit";

export const daycareSlice = createSlice({
  name: "profile/daycare",
  initialState: {
    profiles: [
      {
        //step 1
        // user_id: userData.user_id,
        daycare_name: "",
        daycare_address: "",
        apartment: "",
        city: {},
        state: {},
        country: {},
        postal_code: "",
        contact_first_name: "",
        contact_last_name: "",
        business_email: "",
        phone_number: "",
        website: "",
        daycare_description: "",
        //step 2
        daycare_gallery: [],
        //step 3
        license_type: "",
        hours: { from: {}, to: {} },
        monthly_cost: [{ child: "", value: "" }],
        application_fee: "",
        child_ratio: [{ child: "", value: "" }],
        total_spots: [{ child: "", value: "" }],
        primary_philosophy: {},
        availability: null,
        meals: [],
        subsidy_type: {},
        aid_trained: null,
        late_fees: null,
        outdoor_time: [{ child: "", value: "" }],
        air_conditioned: null,
        insurance: [],
        //step 4
        bank_name: "",
        account_type: {},
        branchAndtransit: "",
        financialInstitution: "",
        account_number: "",
        profile_status: false,
      
    }], // Change to an array
  },
  reducers: {
    setDaycareProfile: (state, action) => {
      // console.log("🚀 ~ state.profiles:", state.profiles.map(obj => ({ ...obj })));
      // console.log("🚀 ~ action.payload:", action.payload)
      const existingIndex = state.profiles?.findIndex(profile => profile.user_id === action.payload.user_id);
      // console.log("🚀 ~ existingIndex:", existingIndex)
      if (typeof existingIndex !== 'undefined'&&existingIndex !== -1) {
        // If the profile already exists, update it
        // console.log("existingIndex inside if")
        state.profiles[existingIndex] = action.payload;
      } else {
        // If the profile doesn't exist, add it to the array
        // console.log("existingIndex outside")
        state.profiles?.push(action.payload);
      }
    },

    clearDaycareProfiles: (state) => {
      state.profiles = []; // Clearing the array
    },
  },
});

export const {
  setDaycareProfile,
  clearDaycareProfiles,
} = daycareSlice.actions;

export default daycareSlice.reducer;

