import authRoute from "./authRoute";
import pagesRoute from "./pagesRoute";
import commonRoute from "./commonRoute";
import daycareRoute from "./daycareRoute";
import parentRoute from "./parentRoute";
export const publicRoutes = [...authRoute, ...pagesRoute];

export const protectedRoutes = [
  ...pagesRoute,
  ...commonRoute,
  ...daycareRoute,
  ...parentRoute,
];
