import React from "react";

const DropdownSvg = () => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.5C0 0.671719 0.667547 0 1.49068 0H19.3789C20.2034 0 20.8696 0.671719 20.8696 1.5C20.8696 2.32969 20.2034 3 19.3789 3H1.49068C0.667547 3 0 2.32969 0 1.5ZM0 9C0 8.17031 0.667547 7.5 1.49068 7.5H19.3789C20.2034 7.5 20.8696 8.17031 20.8696 9C20.8696 9.82969 20.2034 10.5 19.3789 10.5H1.49068C0.667547 10.5 0 9.82969 0 9ZM19.3789 18H1.49068C0.667547 18 0 17.3297 0 16.5C0 15.6703 0.667547 15 1.49068 15H19.3789C20.2034 15 20.8696 15.6703 20.8696 16.5C20.8696 17.3297 20.2034 18 19.3789 18Z"
        fill="#FF6131"
      />
    </svg>
  );
};

export default DropdownSvg;
