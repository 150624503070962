import React from "react";

const Logo = ({onClick}) => {
 return (
  <>
   <svg onClick={onClick} width="155" height="26" viewBox="0 0 155 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.05922 20.6768C3.60744 20.6768 2.24365 20.8088 1.09983 21.0288C0.659898 20.1049 0.351945 18.7411 0.351945 17.4213C0.351945 11.7902 5.71912 10.7783 7.74281 7.47885C4.31134 7.47885 2.28765 7.96278 0.835871 9.06261C0.307952 7.96278 0 6.42301 0 5.10322C0 1.80373 1.89171 0 5.36717 0C9.1066 0 11.7902 0.92386 14.5618 0.92386C15.9695 0.92386 17.0694 0.747886 17.8173 0.351947C18.6091 1.40778 19.0491 2.94755 19.0491 4.35533C19.0491 9.98647 13.242 10.9983 11.8342 13.8579H13.5939C15.4416 13.8579 17.2893 13.286 18.6091 12.3621C19.181 13.022 19.709 14.8257 19.709 16.2775C19.709 19.709 17.9932 21.3807 14.2978 21.3807C10.6904 21.4247 8.05076 20.6768 5.05922 20.6768Z" fill="#00C1C1" />
    <path d="M21.5569 15.3978L21.7769 12.7582C21.9969 10.1186 21.5129 7.43504 20.853 5.67531C22.2608 4.66347 23.9765 4.13555 26.0002 4.13555C29.7837 4.13555 31.6754 6.33521 31.6754 10.7345C31.6754 12.3623 31.5434 13.0222 31.2794 14.254C31.4114 14.342 31.5874 14.386 31.7633 14.386C32.5112 14.386 33.1271 13.4181 33.0831 10.7785L32.9952 5.1034C34.4909 4.4435 36.2067 4.09155 38.0984 4.09155C41.4859 4.09155 43.0256 5.41135 43.6855 7.65501C43.3776 8.44689 42.8496 9.0188 42.6737 9.19477L42.5417 12.6702C42.4977 13.5061 42.8936 13.8141 43.5535 13.8141C43.8175 13.8141 44.3014 13.7261 44.6094 13.5061C44.8733 14.254 45.0053 14.9579 45.0053 15.6178C45.0053 18.8293 42.3217 21.4249 38.3623 21.4249C35.2828 21.4249 33.3471 19.8851 33.787 16.8056H33.2151C32.0713 19.6212 29.7837 21.4249 26.8801 21.4249C23.0527 21.4249 21.249 19.1372 21.5569 15.3978Z" fill="#00C1C1" />
    <path d="M47.5126 13.2421L48.3044 9.10675C48.5244 7.83094 48.0845 7.30302 47.1606 7.30302C46.5887 7.30302 46.1048 7.479 45.7968 7.78695C45.5329 6.95108 45.4009 6.24719 45.4009 5.58729C45.4009 2.59575 47.6885 0.0881348 52.0439 0.0881348C56.3112 0.0881348 58.2469 2.50776 58.2469 6.02722C58.2469 6.59913 58.2029 7.30302 58.1149 8.0949H59.4347C59.9186 2.77172 62.7782 0.0881348 66.1657 0.0881348C68.8053 0.0881348 70.3011 1.67189 70.3011 4.2235C70.3011 6.64312 67.9254 10.0306 62.9982 10.7785V11.0864C63.7461 11.3944 64.6259 11.5704 65.8577 11.5704C67.6175 11.5704 69.6411 10.9985 70.477 10.2946C72.2807 14.3859 71.4009 21.5128 65.0219 21.5128C60.9305 21.5128 58.5988 18.8292 59.3907 13.2421H57.763C57.631 15.6177 57.631 18.2573 58.0269 20.8089C56.4872 21.3809 54.6835 21.5128 53.4516 21.5128C47.8645 21.4248 46.6767 17.5534 47.5126 13.2421Z" fill="#504CBC" />
    <path d="M72.7202 13.1981C72.7202 6.55517 78.3953 4.09155 83.1026 4.09155C88.3818 4.09155 91.4613 6.55517 91.4613 10.9985C91.4613 11.6144 91.3733 12.2743 91.2414 13.0222L82.4867 13.3301C82.9266 14.43 83.9385 15.0019 85.8302 15.0019C87.15 15.0019 88.4698 14.7819 90.2295 13.7701C90.7134 13.946 91.1534 14.9579 91.1534 16.1457C91.1534 18.5213 89.4376 21.6888 82.8387 21.6888C75.9317 21.6449 72.7202 18.0374 72.7202 13.1981ZM82.2227 11.5264L84.5104 11.2185C84.7744 10.1186 84.5544 8.57886 83.6305 8.57886C82.9266 8.57886 82.2667 9.67869 82.2667 11.4384V11.5264H82.2227Z" fill="#504CBC" />
    <path d="M93.0454 13.1981C93.0454 6.55517 98.7205 4.09155 103.428 4.09155C108.707 4.09155 111.787 6.55517 111.787 10.9985C111.787 11.6144 111.699 12.2743 111.567 13.0222L102.812 13.3301C103.252 14.43 104.264 15.0019 106.155 15.0019C107.475 15.0019 108.795 14.7819 110.555 13.7701C111.039 13.946 111.479 14.9579 111.479 16.1457C111.479 18.5213 109.763 21.6888 103.164 21.6888C96.2129 21.6449 93.0454 18.0374 93.0454 13.1981ZM102.548 11.5264L104.836 11.2185C105.1 10.1186 104.88 8.57886 103.956 8.57886C103.252 8.57886 102.592 9.67869 102.592 11.4384V11.5264H102.548Z" fill="#504CBC" />
    <path d="M112.798 21.9088C112.798 20.677 113.106 19.3572 113.458 17.9934L113.59 12.8902C113.678 9.81067 113.194 7.43504 112.71 5.67531C114.162 4.66347 115.966 4.09155 117.902 4.09155C121.069 4.09155 122.477 5.58733 122.477 7.78699C122.477 8.05095 122.433 8.3149 122.433 8.57886H123.005C124.017 5.76329 126.04 4.09155 128.636 4.09155C132.243 4.09155 134.707 7.0831 134.707 12.6262C134.707 18.1254 131.672 21.4249 127.932 21.4249C125.82 21.4249 124.105 20.413 123.401 19.1372H123.093C123.181 22.0848 123.577 23.7125 123.841 24.4164C122.169 25.5163 120.189 26.0002 118.034 26.0002C114.426 26.0002 112.798 24.4164 112.798 21.9088ZM123.137 16.7176C124.413 16.5856 125.073 15.0899 125.073 13.5501C125.073 12.0983 124.501 11.1745 123.533 11.1745H123.313L123.137 16.7176Z" fill="#504CBC" />
    <path d="M136.511 21.0287L136.863 13.9458C136.995 10.7343 136.731 8.27071 135.807 5.41115C137.347 4.57528 138.931 4.13535 140.91 4.13535C144.782 4.13535 145.574 6.07105 145.222 9.76648H145.97C145.97 5.80709 147.509 4.04736 149.621 4.04736C150.809 4.04736 151.777 4.4433 152.876 4.35531C153.536 5.45515 154.064 7.08289 154.064 8.84262C154.064 11.4822 152.789 14.1218 150.193 14.1218C148.697 14.1218 147.553 13.6379 146.849 12.538H146.541L146.761 21.0287H136.511Z" fill="#504CBC" />
   </svg>
  </>
 );
};

export default Logo;