import { unAuthenticatedPages } from "constants/route.constant";

export const isUnauthenticatedPage = (path) => {
    return unAuthenticatedPages.some(page => {
        if (page === path) {
            // Exact match
            return true;
        }
        // Check for pattern match
        const pattern = new RegExp("^" + page.replace(/:[^\s/]+/g, '([^\\/]+)') + "$");
        return pattern.test(path);
    });
};