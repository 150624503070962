import React from "react";
import { PARENT } from "../../constants/roles.constant";

const parentRoute = [
  {
    key: "parent-home",
    path: `/`,
    component: React.lazy(() => import("../../views/pages/home")),
    authority: [PARENT],
    header: true,
    footer: true,
  },
  {
    key: "parent-payment-history",
    path: `/payment-history`,
    component: React.lazy(() => import("../../views/pages/payment/paymentHistory")),
    authority: [PARENT],
    header: true,
    footer: false,
  },
  {
    key: "parent-payment-success",
    path: `/registration-fee-payment-success`,
    component: React.lazy(() => import("../../views/pages/payment/registrationPaymentSuccess")),
    authority: [PARENT],
    header: true,
    footer: false,
  },
  {
    key: "parent-payment-failed",
    path: `/registration-fee-payment-failed`,
    component: React.lazy(() => import("../../views/pages/payment/registrationPaymentFailed")),
    authority: [PARENT],
    header: true,
    footer: false,
  },
  {
    key: "parent-payment-success",
    path: `/payment-success`,
    component: React.lazy(() => import("../../views/pages/payment/paymentSuccess")),
    authority: [PARENT],
    header: true,
    footer: false,
  },
  {
    key: "parent-payment-failed",
    path: `/payment-failed`,
    component: React.lazy(() => import("../../views/pages/payment/paymentFailed")),
    authority: [PARENT],
    header: true,
    footer: false,
  },
  {
    key: "parent-application-details",
    path: `/application-details/:daycare_profile_id/:application_id/:child_info_id/:status/:registration_fee_status/:placement_date/:deposit`,
    component: React.lazy(() => import("../../views/pages/home/components/parentApplicationDetails")),
    authority: [PARENT],
    header: true,
    footer: false,
  },
  {
    key: "parent-browseDaycares",
    path: `/browse-daycares`,
    component: React.lazy(() => import("../../views/pages/browseDaycares")),
    authority: [],
    header: true,
    footer: true,
  },
  // {
  //   key: "parent-daycareDetails",
  //   path: `/browse-daycares/daycare-details/:id`,
  //   component: React.lazy(() => import("../../views/pages/browseDaycares/components/daycareDetails")),
  //   authority:[],
  //   header: true,
  //   footer: false,
  // },
  {
    key: "parent-childProfiles",
    path: `/profiles`,
    component: React.lazy(() => import("../../views/profileForm/editParentProfile")),
    authority: [PARENT],
    header: true,
    footer: true,
  },
  {
    key: "parent-myFavourites",
    path: `/my-favourites`,
    component: React.lazy(() => import("../../views/pages/myFavourites")),
    authority: [PARENT],
    header: true,
    footer: true,
  },
  {
    key: "parent-myFavourites",
    path: `/my-favourites/daycare-details/:id`,
    component: React.lazy(() => import("../../views/pages/browseDaycares/components/daycareDetails")),
    authority: [PARENT],
    header: true,
    footer: true,
  },
  {
    key: "parent-editProfile",
    path: `/edit-parent-profile`,
    component: React.lazy(() => import("../../views/profileForm/editParentProfile")),
    authority: [PARENT],
    header: true,
    footer: true,
  },
];

export default parentRoute;
