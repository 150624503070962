import React, { useState, useEffect } from "react";
import Layout from "./components/layout";
import useAuth from "../src/utils/hooks/useAuth";
import Header from "components/template/header";
import Footer from "components/template/footer";
// import appConfig from "configs/app.config";
import { protectedRoutes, publicRoutes } from "../src/configs/routes.config";

import { useSelector } from "react-redux";
import { isUnauthenticatedPage } from "utils/functions/isUnauthenticatedPage";

function App() {
  // console.log("🚀 ~ App ~ location:", window.location.pathname)
  const { authenticated } = useAuth();
  // console.log("🚀 ~ App ~ authenticated:", authenticated)
  const { userData } = useSelector((state) => state?.auth?.user);
  // console.log("🚀 ~ App ~ userData:", userData)
  const [headerVisible, setHeaderVisible] = useState(false);
  const [footerVisible, setFooterVisible] = useState(false);

  const headlessPages = ["/select-account", "/agreement", "/profile-notification", "/daycare-profile", "/parent-profile"]

  // Use useEffect to update visibility based on location and authentication
  useEffect(() => {
    if (
      isUnauthenticatedPage(window.location.pathname) ||
      window.location.pathname === "/" ||
      authenticated
    ) {
      // console.log("Enter into primary If")
      // condition for default page and authenticated entry
      if (authenticated && userData && userData?.authority[0] === "user") {
        if (headlessPages.includes(window.location.pathname)) {
          setHeaderVisible(false);
          setFooterVisible(false);
        } else {
          setHeaderVisible(true);
          setFooterVisible(true);
        }
      } else if (authenticated && userData && userData?.authority[0] === "daycare") {
        if (headlessPages.includes(window.location.pathname)) {
          setHeaderVisible(false);
          setFooterVisible(false);
        } else {
          setHeaderVisible(true);
          setFooterVisible(true);
        }

      } else if (authenticated && userData && userData?.authority[0] === "parent") {
        if (headlessPages.includes(window.location.pathname)) {
          setHeaderVisible(false);
          setFooterVisible(false);
        }
        else if (window.location.pathname == "/payment-success" || window.location.pathname == "/payment-failed" || window.location.pathname == "/registration-fee-payment-success" || window.location.pathname == "/registration-fee-payment-failed") {
          setHeaderVisible(true);
          setFooterVisible(false);
        }
        else {
          setHeaderVisible(true);
          setFooterVisible(true);
        }
      } else {
        setHeaderVisible(true);
        setFooterVisible(true);
      }
    } else if (window.location.pathname == `/auth/sign-in`) {
      setHeaderVisible(true);
      setFooterVisible(true);
    }
    else {
      // condition for auth page
      // console.log("Enter into primary else")
      setHeaderVisible(false);
      setFooterVisible(false);
    }
  }, [window.location.pathname, authenticated]);
  // console.log("🚀 ~ App ~ filter :|| ", protectedRoutes?.filter((route)=>route.path===window.location.pathname).length<1)
  return (
    <>
      <div className={`top-0 ${window.location.pathname == '/payment-success' || window.location.pathname == '/payment-failed' || window.location.pathname == "/registration-fee-payment-success" || window.location.pathname == "/registration-fee-payment-failed" || window.location.pathname == `/auth/sign-in` ? "fixed" : "sticky"} w-full z-10 shadow-lg`}>{headerVisible && <Header />} </div>
      <Layout />
      {footerVisible && <Footer />}
    </>
  );
}

export default App;
