import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import { Logo } from "../../assets/svg";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "utils/hooks/useAuth";
import { setUser } from "store/auth/userSlice";
import { Button } from "components/ui";

import { MdClose, MdDelete } from "react-icons/md";
import { BiLogOut } from "react-icons/bi";
import { FaArrowLeft } from "react-icons/fa6";
import { GiCheckMark } from "react-icons/gi";
import { FiPlus } from "react-icons/fi";
import { RxHamburgerMenu } from "react-icons/rx";
import axiosInstance from "apiServices/axiosInstance";

const MobileNavLink = ({ isActive, onClick, children }) => {
  // console.log("🚀 ~ MobileNavLink ~ isActive:", isActive)
  return (
    <button
      className={`text-gray-600 w-full relative overflow-hidden ${isActive ? "bg-[#DFEEFF]" : ""
        }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

// Define navigation links
const navigationDaycareMobileLinks = [
  { id: "/", label: "Enrolled Children" },
  { id: "/applications", label: "Applications" },
  // { id: "/waitlist", label: "Waitlist" },
  { id: "/view-daycare-teachers", label: "Staff" },
  { id: "/view-daycare-profile", label: "View Profile" },
  { id: "/edit-daycare-profile", label: "Edit Profile" },

];
const navigationParentMobileLinks = [
  { id: "/", label: "Home" },
  { id: "/browse-daycares", label: "Browse Daycares" },
  { id: "/profiles", label: "Profiles" },
  { id: "/my-favourites", label: "My Favourites" },
];
const navigationMobileLinks = [
  { id: "/", label: "Browse Daycares" }
];

const Header = (props) => {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const dispatch = useDispatch();
  const { pathname } = useLocation()
  const { userData } = useSelector((state) => state?.auth?.user);
  const [isInCompleteFormModelVisible, setIsInCompleteFormModelVisible] = useState(false);
  const dropdownContentRef = useRef(null);
  const [isTogglerOpen, setIsTogglerOpen] = useState(false);
  // const [isSwitchProfileOpen, setSwitchProfileOpen] = useState(false);
  const [selectedProfile, setSelectedprofile] = useState({
    value: "",
    label: "",
  });
  const [userRole, setUserRole] = useState("user");
  const [accounts, setAccounts] = useState([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);

  const dropdownRef = useRef(null);
  useEffect(() => {
    // Function to handle clicks outside the dropdown
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current?.contains(event.target) &&
        !dropdownContentRef.current?.contains(event.target)
      ) {
        setIsTogglerOpen(false);
      }
    };
    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // set role
    if (userData && userData?.authority[0]) {
      setUserRole(userData.authority[0]);
    } else {
      //add by me
      setUserRole("user");
    }

    // set accounts and profile
    if (userData?.profile_data?.length && userData.selected_profile) {
      setAccounts(userData.profile_data);
      setSelectedprofile(userData.selected_profile);
    } else {
      //add by me
      setSelectedprofile({
        value: "user",
        label: "user",
      });
      setAccounts([{ value: "user", label: "user" }]);
    }
  }, [userData]);


  const toggleMenu = () => {
    if (userData && userData?.authority[0] == "daycare") {
      if (userData?.profile_id.value == "user") {
        setIsInCompleteFormModelVisible(true)
        // setIsTogglerOpen(!isTogglerOpen);
      } else {
        setIsTogglerOpen(!isTogglerOpen);
      }
    } else {
      setIsTogglerOpen(!isTogglerOpen);
    }
  };

  const handleMobileLinkClick = (link) => {
    // console.log("🚀 ~ handleMobileLinkClick ~ link:", link)
    navigate(link)
    setIsTogglerOpen(false); // Close the mobile menu when a link is clicked
  };

  // const toggleSwitchProfile = async () => {
  //   setSwitchProfileOpen(!isSwitchProfileOpen);
  // };
  // const handleAccountSelect = async (account) => {
  //   //mobile
  //   setSelectedprofile(account);
  //   await dispatch(
  //     await setUser({
  //       ...userData,
  //       selected_profile: account,
  //     })
  //   );
  //   setSwitchProfileOpen(false);
  // };


  // Web side funtions click
  const clickLogout = (e) => {
    // console.log("click logout");
    e.stopPropagation()
    signOut();
    navigate("/");
    setIsTogglerOpen(false);
  };
  const clickLogin = () => {
    // console.log("click logout");
    signOut();
    navigate("/auth/sign-in");
    setIsTogglerOpen(false);
  };
  const clickSignUp = () => {
    // console.log("click sign-up");
    navigate("/auth/sign-up");
    setIsTogglerOpen(false);
  };
  const clickDaycareTeachers = () => {
    navigate("/view-daycare-teachers");
    setIsTogglerOpen();
  };
  const clickViewMyTransactions = () => {
    navigate("/transactions");
    setIsTogglerOpen();
  };
  const clickViewDaycareProfile = () => {
    navigate("/view-daycare-profile");
    setIsTogglerOpen();
  };
  const clickEditDaycareProfile = () => {
    navigate("/edit-daycare-profile");
    setIsTogglerOpen();
  };
  const clickAddDaycareProfile = () => {
    navigate("/daycare-profile");
    setIsTogglerOpen();
    setIsTogglerOpen(false);
  };
  const clickDeleteDaycareProfile = (e) => {
    e.stopPropagation();
    setIsDeleteModalVisible(true);
    // toggleSwitchProfile();
  };
  const clickHandleConfirm = async (event, profile_id) => {
    // console.log("🚀 ~ clickHandleConfirm ~ profile_id:", profile_id)
    event.stopPropagation(); // Prevent bubbling
    try {
      setDeleteIsLoading(true)
      const response = await axiosInstance.delete(`user/daycare-profile/delete-daycare-details/${profile_id}/${userData.user_id}`);
      // console.log("🚀 ~ clickHandleConfirm ~ response:", response)
      if (response.status) {
        setDeleteIsLoading(false)
        signOut();
        navigate("/");
        setIsDeleteModalVisible(false);
      } else {
        setIsDeleteModalVisible(true);
        setDeleteIsLoading(false)
      }
    } catch (error) {
      // console.log("getOneDaycareDetailsData error:", error.message);
      setDeleteIsLoading(false)
    }
  };
  const clickHandleCancel = (event) => {
    // console.log("clickHandleCancel")
    event.stopPropagation(); // Prevent bubbling
    setIsDeleteModalVisible(false);
  };
  const clickSwitchProfile = async (data) => {
    // Web
    // console.log("data : ", data);
    setSelectedprofile(data);
    await dispatch(
      await setUser({
        ...userData,
        selected_profile: data,
      })
    );
    setIsTogglerOpen(false);
  };
  const clickCompleteProfile = () => {
    // console.log("click profiles");
    navigate("/daycare-profile");
    // navigate("/profiles");
  };
  const handleCancel = (e) => {
    e.stopPropagation();
    setIsInCompleteFormModelVisible(false);
  };
  const RoleWiseHeader = (role) => {
    try {
      switch (role) {
        case "daycare":
          return (
            <>
              <div className="flex justify-between item-center font-avenir py-3  lg:py-5">
                {/* Left-aligned section*/}
                <div className="flex items-center gap-x-4 ">
                  <Link to="/">
                    <Logo />
                  </Link>
                  <div className="hidden lg:flex lg:gap-x-4">
                    {/* Navigation buttons */}

                    <NavLink
                      className={({ isActive }) => `text-[#333333]  hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${isActive ? "bg-[#504CBC] text-white" : ""}`}
                      to="/"
                    >
                      Enrolled Children
                    </NavLink>
                    <NavLink
                      className={({ isActive }) => `text-[#333333] hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${isActive ? "bg-[#504CBC] text-white" : ""}`}
                      to="/applications"
                    >
                      Applications
                    </NavLink>
                    {/* <NavLink
                      className={({ isActive }) => `text-[#333333] hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${isActive ? "bg-[#504CBC] text-white" : ""}`}
                      to="/waitlist"
                    >
                      Waitlist
                    </NavLink> */}
                  </div>
                </div>

                {/* Right-aligned section */}
                <div className="flex items-center">
                  <div className="lg:hidden">
                    <RxHamburgerMenu
                      color="#504CBC"
                      onClick={toggleMenu}
                      size={25}
                    />
                    {isInCompleteFormModelVisible && (
                      <div className="fixed top-0 left-0 z-10 w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="container">
                          <div className="w-full mx-auto bg-white rounded-2xl overflow-hidden shadow-md  relative sm:w-3/4  lg:w-1/2 2xl:w-1/3">
                            <MdClose className="absolute top-2 right-3  cursor-pointer" color="#ffffff" size={25} onClick={(e) => handleCancel(e)} />
                            <div className="bg-[#504CBC] text-white text-center font-bold font-avenirbold py-[10px]">
                              <span className="text-[24px] ">Note</span>
                            </div>

                            <div className="text-center px-4 py-2 font-avenir lg:text-2xl">
                              <span>
                                You must completed your daycare profile with
                                details.
                              </span>
                            </div>

                            <div className="flex flex-col items-center gap-2 py-4 ">
                              <Button
                                variant="solid"
                                className="w-[240px]  border-[1px] rounded-xl text-base font-bold align-middle text-[#504CBC] "
                                onClick={() => clickCompleteProfile()}
                              >
                                Complete Profile
                              </Button>
                              <Button
                                className="w-[240px]   align-middle"
                                color="#FF6131"
                                onClick={() => clickLogout()}
                              >
                                Logout
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {isTogglerOpen && (
                      <div ref={dropdownRef} className="fixed top-0 right-0 sm:w-1/2 w-full h-full bg-white z-40 shadow-lg overflow-scroll">
                        <nav className="flex flex-col items-start ">

                          <div className="w-full border-b-[2px] border-[#504CBC] flex items-center  text-center pt-1 pb-2">
                            <div className="w-[10%]">
                              <FaArrowLeft
                                className="ml-[16px] text-[#504CBC]"
                                size={25}
                                onClick={() => {
                                  setIsTogglerOpen(false);
                                }}
                              />
                            </div>
                            <div className="font-avenir text-center font-bold text-[24px] text-[#504CBC] w-[90%]">
                              Menu
                            </div>
                          </div>

                          <div className="w-full px-5 pt-6 pb-2.5 bg-gray-100">
                            <div className="sm:w-full  sm:pt-[0px] pt-[10px]">
                              <div className="font-outfit  text-base text-[#333333] font-bold capitalize">
                                <span>  Hello, {userData?.selected_profile?.daycare_name ? `${userData.selected_profile.daycare_name}` : `Daycare`}</span>
                              </div>
                            </div>
                            {/* <div className="sm:pt-0 pt-3.5 underline underline-offset-4 decoration-[#504CBC] text-[#000000]">
                            <span className="font-semibold text-sm">
                              Edit Profile
                            </span>
                          </div> */}
                          </div>

                          {navigationDaycareMobileLinks?.map((link) => {
                            // console.log("🚀 ~ {navigationDaycareMobileLinks?.map ~ link:", link)
                            return (
                              <>
                                <MobileNavLink
                                  key={link.id}
                                  isActive={pathname === link.id}
                                  onClick={() => {
                                    // console.log(" ")
                                    handleMobileLinkClick(link.id);
                                    setIsTogglerOpen(false);
                                  }}
                                >
                                  <span className="text-[#333333] font-medium font-avenir hover:bg-[#DFEEFF] w-full py-[10px] pl-[16px] flex text-[18px]">
                                    {link.label}
                                  </span>
                                </MobileNavLink>
                              </>
                            )
                          })}


                          <div className="pt-2">
                            <span className="text-[12px] pl-[15px] font-bold text-black">
                              Switch Account
                            </span>
                          </div>
                          {accounts.length &&
                            accounts.map((info, index) => {
                              // console.log("🚀 ~ accounts.map ~ info:", info)
                              return (
                                <>
                                  {info.daycare_name === selectedProfile.daycare_name ? (
                                    <>
                                      <div className="flex justify-between items-center bg-gray-200
                                      px-4 py-[10px] text-[16px] text-[#FF6131] hover:bg-gray-100 w-full text-left capitalize">
                                        <div>
                                          <button
                                            disabled={true}
                                            className={``}
                                          >
                                            {info.daycare_name}
                                          </button>
                                        </div>
                                        <div className="flex items-center">
                                          <span className=" text-[#504CBC] mr-4">
                                            <GiCheckMark />
                                          </span>
                                          <span className=" text-[#504CBC] mr-4">
                                            <MdDelete
                                              onClick={(e) => clickDeleteDaycareProfile(e)}
                                            />
                                            {isDeleteModalVisible && (
                                              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
                                                <div className="w-1/4 mx-auto bg-white rounded-xl overflow-hidden shadow-md border-[1px] border-black">
                                                  <div className="bg-[#504CBC] text-white text-center font-bold font-avenirbold">
                                                    <span className="text-[24px]">Delete</span>
                                                  </div>
                                                  <div className="px-6 py-4">
                                                    <div className="text-center">
                                                      <span className="font-medium text-[24px] text-[#000000] text-center">
                                                        Confirm delete Daycare profile
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="flex justify-center pt-[28px]">
                                                    <Button
                                                      variant="solid"
                                                      className=" h-[40px] w-[150px] text-xl border-[1px] font-bold rounded-xl mx-9 flex items-center justify-center"
                                                      color={"#00C1C1"}
                                                      loading={deleteIsLoading}
                                                      onClick={(event) => clickHandleConfirm(event, info.profile_id)}
                                                    >
                                                      Confirm
                                                    </Button>
                                                  </div>
                                                  <div className="flex justify-center pt-[28px] pb-[30px]">
                                                    <Button
                                                      variant="solid"
                                                      className="bg-[#828282] text-[#ffffff] h-[40px] w-[150px] text-xl border-[1px] font-bold rounded-xl mx-9 flex items-center justify-center"
                                                      color={"#828282"}
                                                      onClick={(event) => clickHandleCancel(event)}
                                                    >
                                                      Cancel
                                                    </Button>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        onClick={(e) => {
                                          e.preventDefault();
                                          // console.log("dfdfdf");
                                          clickSwitchProfile(info);
                                        }}
                                        className={`flex justify-between items-center px-4 py-[10px] ] text-[16px] text-[#000000] hover:bg-gray-100 w-full text-left capitalize`}
                                      >
                                        <div>
                                          <button>
                                            {info.daycare_name}
                                          </button>
                                        </div>
                                        <div className="flex">
                                          <span className=" text-[#000000] mr-4">

                                          </span>
                                          <span className=" text-[#000000] mr-4">
                                            {/* <MdDelete
                                              onClick={(e) => clickDeleteDaycareProfile(e)}
                                            /> */}

                                          </span>
                                        </div>

                                      </div>
                                    </>
                                  )}
                                </>
                              );
                            })}

                          <div>
                            <button
                              className="px-4 py-2 w-full rounded-md focus:outline-none  bg-[#fff]   text-[#757575] font-semibold text-base"
                              onClick={clickAddDaycareProfile}
                            >
                              <div className="flex items-center">
                                <FiPlus />
                                <span className="pl-[10px]">
                                  Add another account
                                </span>
                              </div>
                            </button>
                          </div>

                          <div>
                            <Button
                              variant="solid"
                              icon={<BiLogOut />}
                              onClick={(e) => clickLogout(e)}
                              className="py-[10px] px-[30px] my-[40px] ml-[16px] rounded-md bg-[#504CBC] border  text-[#ffffff] font-medium text-[18px]"
                            >
                              Logout
                            </Button>
                          </div>

                        </nav>
                      </div>
                    )}

                    {/* {isTogglerOpen && (
                      <div
                        className="fixed top-0 left-0 w-full h-fit bg-black opacity-50 z-30"
                        onClick={toggleMenu}
                      ></div>
                    )} */}
                  </div>

                  <div className="hidden lg:flex lg:justify-end  lg:gap-x-4  lg:items-center">
                    <div className="flex items-center gap-2">
                      <span className="text-[#333333] font-black text-base pr-2 capitalize line-clamp-1">
                        Hello, {userData?.selected_profile?.daycare_name ? `${userData.selected_profile.daycare_name}` : `Daycare`}
                      </span>

                      <div className="cursor-pointer" >
                        <RxHamburgerMenu
                          className="text-[#FF6131]"
                          onClick={toggleMenu}
                          size={25}
                        />
                      </div>
                      {isInCompleteFormModelVisible && (
                        <div className="fixed top-0 left-0 z-10 w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center">
                          <div className="container">
                            <div className="w-full mx-auto bg-white rounded-2xl overflow-hidden shadow-md  relative sm:w-3/4  lg:w-1/2 2xl:w-1/3">
                              <MdClose className="absolute top-2 right-3  cursor-pointer" color="#ffffff" size={25} onClick={(e) => handleCancel(e)} />
                              <div className="bg-[#504CBC] text-white text-center font-bold font-avenirbold py-[10px]">
                                <span className="text-[24px] ">Note</span>
                              </div>

                              <div className="text-center px-4 py-2 font-avenir lg:text-2xl">
                                <span>
                                  You must completed your daycare profile with
                                  details.
                                </span>
                              </div>

                              <div className="flex flex-col items-center gap-2 py-4 ">
                                <Button
                                  className=""
                                  onClick={() => clickCompleteProfile()}
                                >
                                  Complete Profile
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {isTogglerOpen && (
                        <div
                          ref={dropdownContentRef}
                          className="dropdown-content transition-all absolute top-16 w-[300px] mt-2 origin-top-right bg-white border border-gray-200 divide-y  divide-[#BDBDBD] rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
                        >
                          <div className="px-4 py-2">
                            <button onClick={clickDaycareTeachers} className="text-black text-[16px] text-center">
                              Staff
                            </button>
                          </div>
                          <div className="px-4 py-2">
                            <button onClick={clickViewDaycareProfile} className="text-black text-[16px] text-center">
                              View profile
                            </button>
                          </div>
                          <div className="px-4 py-2">
                            <button onClick={clickEditDaycareProfile} className="text-black text-[16px] text-center">
                              Edit profile
                            </button>
                          </div>
                          <div className="pt-2">
                            <span className="text-[12px] pl-[15px] font-bold text-black">
                              Switch Account
                            </span>
                          </div>
                          {accounts.length &&
                            accounts.map((info, index) => {
                              // console.log("🚀 ~ accounts.map ~ info:", info)
                              return (
                                <>
                                  {info.daycare_name === selectedProfile.daycare_name ? (
                                    <>
                                      <div className="flex justify-between items-center bg-gray-200
                                      px-4 py-[10px] text-[16px] text-[#FF6131] hover:bg-gray-100 w-full text-left capitalize">
                                        <div>
                                          <button
                                            disabled={true}
                                            className={``}
                                          >
                                            {info.daycare_name}
                                          </button>
                                        </div>
                                        <div className="flex items-center">
                                          <span className=" text-[#504CBC] mr-4">
                                            <GiCheckMark />
                                          </span>
                                          <span className=" text-[#504CBC] mr-4">
                                            <MdDelete
                                              onClick={(e) => clickDeleteDaycareProfile(e)}
                                            />
                                            {isDeleteModalVisible && (
                                              <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                                                <div className="container">
                                                  <div className="w-full mx-auto bg-white rounded-[20px] overflow-hidden shadow-md relative sm:w-3/4 lg:w-1/2 2xl:w-1/3 ">
                                                    <div className="bg-[#504CBC] text-white text-center font-bold font-avenirbold">
                                                      <span className="text-[24px]">Delete</span>
                                                    </div>
                                                    <div className="text-center px-4 py-2 font-avenir lg:text-2xl">
                                                      <span className="font-medium text-[24px] text-[#000000] text-center">
                                                        Confirm delete Daycare profile
                                                      </span>
                                                    </div>
                                                    <div className="flex flex-col justify-center  items-center gap-4 px-4 py-6">
                                                      <Button
                                                        className=""
                                                        variant="solid"
                                                        color="#00C1C1"
                                                        loading={deleteIsLoading}
                                                        onClick={(event) => clickHandleConfirm(event, info.profile_id)}
                                                      >
                                                        Confirm
                                                      </Button>
                                                      <Button
                                                        className="text-black"
                                                        color="#828282"
                                                        onClick={(event) => clickHandleCancel(event)}
                                                      >
                                                        Cancel
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        onClick={(e) => {
                                          e.preventDefault();
                                          // console.log("dfdfdf");
                                          clickSwitchProfile(info);
                                        }}
                                        className={`flex justify-between items-center px-4 py-[10px] ] text-[16px] text-[#000000] hover:bg-gray-100 w-full text-left capitalize`}
                                      >
                                        <div>
                                          <button

                                          >
                                            {info.daycare_name}

                                          </button>
                                        </div>
                                        <div className="flex">
                                          <span className=" text-[#000000] mr-4">

                                          </span>
                                          <span className=" text-[#000000] mr-4">
                                            {/* <MdDelete
                                              onClick={(e) => clickDeleteDaycareProfile(e)}
                                            /> */}

                                          </span>
                                        </div>

                                      </div>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          {/* 
                          <button className="block px-4 pb-[10px] pt-[10px] text-[16px] text-[#FF6131] hover:bg-gray-100 w-full text-left">
                            Hilltop Centre
                          </button> */}

                          <button
                            className="block px-4 pb-[10px] pt-[10px] text-[16px] text-[#757575] hover:bg-gray-100 w-full text-left"
                            onClick={clickAddDaycareProfile}
                          >
                            + Add another account
                          </button>
                          {/* Add more switch profile options as needed */}
                        </div>
                      )}

                      {/* Logout button */}
                      <button
                        onClick={clickLogout}
                        className="text-black hover:bg-[#504CBC] text-[16px] font-medium hover:text-white rounded-full px-6 py-2 "
                      >
                        Logout
                      </button>
                    </div>


                  </div>
                </div>
              </div>
            </>
          );
        case "parent":
          return (
            <>
              <div className=" flex justify-between item-center font-avenir py-3  lg:py-5">
                {/* Left-aligned section*/}
                <div className="flex items-center gap-x-4 ">
                  <Link to="/">
                    <Logo />
                  </Link>
                  <div className=" hidden lg:flex lg:gap-x-4 ">
                    {/* Navigation buttons */}
                    <NavLink
                      className={({ isActive }) => ` text-[#333333] hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${isActive ? "bg-[#504CBC] text-white" : ""}`}
                      to="/"
                    >
                      Home
                    </NavLink>
                    <NavLink
                      className={({ isActive }) => ` text-[#333333] hover:bg-[#504CBC] text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${isActive ? "bg-[#504CBC] text-white" : ""}`}
                      to="/browse-daycares"
                    >
                      Browse Daycares
                    </NavLink>
                    <NavLink
                      className={({ isActive }) => ` text-[#333333] hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${isActive ? "bg-[#504CBC] text-white" : ""}`}
                      to="/profiles"
                    >
                      Profiles
                    </NavLink>
                    <NavLink
                      className={({ isActive }) => ` text-[#333333] hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${isActive ? "bg-[#504CBC] text-white" : ""}`}
                      to="/my-favourites"
                    >
                      My Favourites
                    </NavLink>
                  </div>
                </div>

                {/* Right-aligned section */}
                <div className="flex items-center">
                  <div className="lg:hidden">
                    <RxHamburgerMenu
                      color="#504CBC"
                      onClick={toggleMenu}
                      size={25}
                    />
                    {isTogglerOpen && (
                      <div ref={dropdownRef} className="fixed top-0 right-0  w-full h-full bg-white z-40 shadow-lg overflow-scroll sm:w-1/2 ">
                        <nav className="flex flex-col items-start ">

                          <div className="w-full border-b-[2px] border-[#504CBC] flex items-center  text-center pt-1 pb-2">
                            <div className="w-[10%]">
                              <FaArrowLeft
                                className="ml-[16px] text-[#504CBC]"
                                size={25}
                                onClick={() => {
                                  setIsTogglerOpen(false);
                                }}
                              />
                            </div>
                            <div className="font-avenir text-center font-bold text-[24px] text-[#504CBC] w-[90%]">
                              Menu
                            </div>
                          </div>

                          <div className="w-full  px-4 pt-6 pb-2.5 bg-gray-100">
                            <div className="sm:w-full  sm:pt-[0px] pt-[10px]">
                              <div className="font-outfit  text-base text-[#333333] font-bold">
                                <span> Hello, {userData?.selected_profile?.contact_first_name ? `${userData.selected_profile.contact_first_name}` : "Parent"}</span>
                              </div>
                            </div>
                            {/* <div className="sm:pt-0 pt-3.5 underline underline-offset-4 decoration-[#504CBC] text-[#000000]">
                            <span className="font-semibold text-sm">
                              Edit Profile
                            </span>
                          </div> */}
                          </div>
                          {navigationParentMobileLinks.map((link) => {

                            return (
                              <MobileNavLink
                                key={link.id}
                                isActive={pathname === link.id}
                                onClick={() => {
                                  handleMobileLinkClick(link.id);
                                  setIsTogglerOpen(false);
                                }}
                              >
                                <span className="text-[#333333] font-medium font-avenir hover:bg-[#DFEEFF] w-full py-[10px] pl-[16px] flex text-[18px]">
                                  {link.label}
                                </span>
                              </MobileNavLink>
                            )
                          })}


                          <div>
                            <Button
                              variant="solid"
                              icon={<BiLogOut />}
                              onClick={(e) => clickLogout(e)}
                              className="py-[10px] px-[30px] my-[40px] ml-[16px] rounded-md bg-[#504CBC] border  text-[#ffffff] font-medium text-[18px]"
                            >
                              Logout
                            </Button>
                          </div>

                        </nav>
                      </div>
                    )}
                    {isTogglerOpen && (
                      <div
                        className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-30"
                        onClick={toggleMenu}
                      ></div>
                    )}
                  </div>

                  <div className="hidden lg:flex lg:justify-end  lg:gap-x-4  lg:items-center  ">
                    {" "}
                    <div className="flex items-center gap-2">
                      <span className="text-[#333333] font-black text-base mr-4">
                        Hello, {userData?.selected_profile?.contact_first_name ? `${userData.selected_profile.contact_first_name}` : "Parent"}
                      </span>
                    </div>
                    {/* Logout button */}
                    <button
                      className="text-black hover:bg-[#504CBC] text-[16px] hover:text-white rounded-full px-6 py-2 font-medium"
                      onClick={clickLogout}
                    >
                      Logout
                    </button>
                  </div>
                </div>

              </div>
            </>
          );
        default:
          return (
            <>
              <div className="flex justify-between item-center font-avenir py-3 lg:py-5">
                {/* Left-aligned section*/}
                <div className="flex items-center gap-x-4">
                  <Link to="/" >
                    <Logo />
                  </Link>
                  <div className="hidden lg:flex  ">
                    {/* Navigation buttons */}
                    <NavLink
                      className={({ isActive }) => ` text-[#333333]  hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${isActive ? "bg-[#504CBC] text-white" : ""}`}
                      to="/browse-daycares"
                    >
                      Browse Daycares
                    </NavLink>
                  </div>
                </div>

                {/* Right-aligned section */}
                <div className=" flex items-center">

                  <div className="lg:hidden">
                    <RxHamburgerMenu
                      color="#504CBC"
                      onClick={toggleMenu}
                      size={20}
                    />

                    {isTogglerOpen && (
                      <div ref={dropdownRef} className="fixed top-0 right-0  w-full h-full bg-white z-40 shadow-lg overflow-scroll sm:w-1/2 ">
                        <nav className="flex flex-col items-start ">

                          <div className="w-full border-b-[2px] border-[#504CBC] flex items-center  text-center pt-1 pb-2">
                            <div className="w-[10%]">
                              <FaArrowLeft
                                className="ml-[16px] text-[#504CBC]"
                                size={25}
                                onClick={() => {
                                  setIsTogglerOpen(false);
                                }}
                              />
                            </div>
                            <div className="font-avenir text-center font-bold text-[24px] text-[#504CBC] w-[90%]">
                              Menu
                            </div>
                          </div>


                          {navigationMobileLinks.map((link) => (
                            <MobileNavLink
                              key={link.id}
                              isActive={pathname === link.id}
                              onClick={() => {
                                handleMobileLinkClick(link.id);
                                setIsTogglerOpen(false);
                              }}
                            >
                              <span className="text-[18px] text-[#333333] font-medium font-avenir hover:bg-[#DFEEFF] w-full py-[10px] pl-[16px] flex ">
                                {link.label}
                              </span>
                            </MobileNavLink>
                          ))}
                          <div className="flex gap-2 mt-6 ml-4">
                            <div className="">
                              <Button
                                className="text-[18px] text-[#333333] font-medium font-avenir "
                                variant="solid"
                                onClick={() => clickLogin()}
                              >
                                Login
                              </Button>
                            </div>
                            <div>
                              <Button
                                className="text-[18px] text-[#333333] font-medium font-avenir bg-[#FF6131] "
                                variant="solid"
                                color={"#FF6131"}
                                onClick={clickSignUp}
                              >
                                Create Account
                              </Button>
                            </div>
                          </div>
                        </nav>
                      </div>
                    )}

                  </div>

                  <div className="hidden lg:flex lg:justify-end  lg:gap-x-4  lg:items-center  ">
                    {" "}
                    {/* Logout button */}
                    <NavLink className={({ isActive }) => ` text-[#333333]  hover:bg-[#504CBC]  text-[16px] hover:text-white rounded-full px-6 py-2  font-medium ${isActive ? "bg-[#504CBC] text-white" : ""}`}
                      to="/auth/sign-in"
                    >
                      {/* <button
                        className="text-black hover:bg-[#504CBC] text-[16px] hover:text-white rounded-full px-6 py-2 font-medium"
                        onClick={clickLogin}
                      > */}
                      Login
                      {/* </button> */}
                    </NavLink>
                    <button
                      className=" bg-[#FF6131] text-[16px] text-white rounded-full px-6 py-2 font-medium"
                      onClick={clickSignUp}
                    >
                      Create Account
                    </button>
                  </div>

                </div>

              </div >
            </>
          );
      }
    } catch (error) { }
  };

  return (<>
    <div className=" bg-white  border-b-[1px] border-b-[#BDBDBD]">
      <header className="container">{RoleWiseHeader(userRole)}</header>
    </div>
  </>);
};

export default Header;
