import { createSlice } from "@reduxjs/toolkit";

export const parentSlice = createSlice({
  name: "profile/parent",
  initialState: {
    profiles: [
      {
        //step 1
        // user_id:userData?.user_id,
        contact_first_name: "",
        contact_last_name: "",
        parent_address: "",
        apartment: "",
        country: {},
        state: {},
        city: {},
        postal_code: "",
        phone_number: "",
        sameAsHome: false,
        work_address: "",
        work_apartment: "",
        work_country: {},
        work_state: {},
        work_city: {},
        work_postal_code: "",
        //step 2
        parent2_name: "",
        parent3_name: "",
        parent4_name: "",
        emergency_contact_name: "",
        emergency_contact_Phone: "",
        resident_province: {},
        resident_city: {},
        care_state: {},
        care_city: {},
        //step 3
        ideal_date: "",
        backup_date: "",
        number_of_children: "",
        hoping: "",
        regulation: "",
        helps: "",
        //step 4
        child_info: {
          first_name: "",
          last_name: "",
          age: "",
          allergies: [],
          pri_language: "",
          sec_language: "",
          neps: "",
          diapers_potty: "",
          pick_up: "",
          not_pick_up: "",
        }
      }], // Change to an array
  },
  reducers: {
    setParentProfile: (state, action) => {
      // console.log("🚀 ~ state.profiles12345:", state.profiles?.map(obj => ({ ...obj })));
      // console.log("🚀 ~ action.payload:", action.payload)
      const existingIndex = state.profiles?.findIndex(profile => profile.user_id === action.payload.user_id);
      // console.log("🚀 ~ existingIndex:", existingIndex)
      if (typeof existingIndex !== 'undefined' && existingIndex !== -1) {
        // console.log("existingIndex inside if")
        // If the profile already exists, update it
        state.profiles[existingIndex] = action.payload;
      } else {
        // If the profile doesn't exist, add it to the array
        // console.log("existingIndex outside")
        state.profiles?.push(action.payload);
      }
    },

    clearParentProfiles: (state) => {
      state.profiles = []; // Clearing the array
    },
  },
});

export const { setParentProfile, clearParentProfiles } = parentSlice.actions;

export default parentSlice.reducer;

