import React, { memo, useMemo, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import useAuth from "../../utils/hooks/useAuth";
import { isUnauthenticatedPage } from "utils/functions/isUnauthenticatedPage";
import { Spinner } from "components/ui";
// import appConfig from "configs/app.config";

const Layout = () => {
  const { authenticated } = useAuth();
  const userAuthority = useSelector((state) => state.auth.user.userData.authority);
  // console.log("🚀 ~ Layout ~ userAuthority:", userAuthority)
  // console.log("appconfig path : ", appConfig.unAuthenticatedEntryPath);
  // console.log(":rocket: ~ Layout ~ authenticated:", authenticated)

  const AppLayout = useMemo(() => {

   if (isUnauthenticatedPage(window.location.pathname) || (window.location.pathname == "/")||
    authenticated ) {
      // console.log("Enter in to if block")
      return lazy(() => import("./classicLayout"));
    }
    return lazy(() => import("./authLayout"));
  }, [authenticated, window.location.pathname]);

  return (
    <>
      <Suspense
        fallback={
          <div className="h-[100vh]  flex justify-center items-center">
            <Spinner size="3.25rem" />
          </div>
        }
      >
        <AppLayout />
      </Suspense>
    </>
  );
};
export default memo(Layout);
