import React from "react";
import {PARENT, DAYCARE } from "../../constants/roles.constant";
import { DAYCARE_PREFIX_PATH } from "../../constants/route.constant";

const daycareRoute = [
  {
    key: "daycare-ourChildren",
    path: `/`,
    component: React.lazy(() => import("../../views/pages/ourChildren")),
    authority: [DAYCARE],
    header: true,
    footer: false,
  },
  {
    key: "daycare-applications",
    path: `/applications`,
    component: React.lazy(() => import("../../views/pages/applications")),
    authority: [DAYCARE],
    header: true,
    footer: false,
  },
  {
    key: "daycare-application-details",
    path: `/applications/application-details/:parent_profile_id/:child_info_id/:application_id`,
    component: React.lazy(() => import("../../views/pages/applications/components/applicationsDetails")),
    authority: [DAYCARE],
    header: true,
    footer: false,
  },
  // {
  //   key: "daycare-waitlist",
  //   path: `/waitlist`,
  //   component: React.lazy(() => import("../../views/pages/waitlist")),
  //   authority: [DAYCARE],
  //   header: true,
  //   footer: false,
  // },
  {
    key: "daycare-view-profile",
    path: `/view-daycare-profile`,
    component: React.lazy(() => import("../../views/profileForm/viewProfile/viewDaycareProfile")),
    authority: [DAYCARE],
    header: true,
    footer: false,
  },
  {
    key: "daycare-view-teachers",
    path: `/view-daycare-teachers`,
    component: React.lazy(() => import("../../views/profileForm/teachers")),
    authority: [DAYCARE],
    header: true,
    footer: false,
  },
  {
    key: "daycare-edit-profile",
    path: `/edit-daycare-profile`,
    component: React.lazy(() => import("../../views/profileForm/editDaycareProfile")),
    authority: [DAYCARE],
    header: true,
    footer: false,
  }

];

export default daycareRoute;
