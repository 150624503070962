import React from "react";
import {USER,PARENT, DAYCARE } from "../../constants/roles.constant";
import { AUTH_PREFIX_PATH } from "../../constants/route.constant";

const authRoute = [
  {
    key: "user-signIn",
    path: `${AUTH_PREFIX_PATH}/sign-in`,
    component: React.lazy(() => import("../../views/auth/signIn")),
    authority: [],
  },
  {
    key: "user-signUp",
    path: `${AUTH_PREFIX_PATH}/sign-up`,
    component: React.lazy(() => import("../../views/auth/signUp")),
    authority: [],
  },
  {
    key: "user-forgotPassword",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() => import("../../views/auth/forgotPassword")),
    authority: [],
  },
  {
    key: "user-resetPassword",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() => import("../../views/auth/resetPassword")),
    authority: [],
  },
];

export default authRoute;
